html, body, #root {
  width: 100%;
  height: 100%;
  font-family: 'Arvo';
  background: #212121;
}

.Cal__Header__root {
  background-color: #333 !important;
}
.Cal__Weekdays__root {
  background-color: #464646 !important;
}
.Cal__Day__selection {
  background-color: #464646 !important;
}
.react-add-to-calendar__dropdown {
  position: fixed !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100%;
  background: rgba(0,0,0,0.7) !important;
  border: 0 !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
}
.react-add-to-calendar__dropdown ul {
  padding: 10px !important;
  font-size: 1.5em;
  color: #fff !important;
  background: #eee;
}

.react-add-to-calendar__dropdown li {
  border-bottom: solid 1px #333;
  padding: 5px;
}
.react-add-to-calendar__dropdown li a {
  color: #333 !important;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
